import React, {
  useState, createRef, useEffect, useRef,
} from 'react';
import dayjs from 'dayjs';
import Spinner from 'react-bootstrap/Spinner';
import {
  Stack, Container, Row, Col, Form, Button, Alert,
} from 'react-bootstrap';
import {
  CardComponent, CardNumber, CardExpiry, CardCVV,
} from '@chargebee/chargebee-js-react-wrapper'; // @ts-ignore
import { useForm } from 'react-hook-form';
import { Check, CheckCircleFill, CheckLg } from 'react-bootstrap-icons';
import DiscountModal from './DiscountModal';
import Countdown from './Cowntdown';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectSignUp,
  setCbToken,
  setCoupon,
  setMarketingTerms,
} from '../features/signup/signupSlice';
import {
  pineAppleDay,
  pineAppleTime,
} from '../lib/utils';
import {
  selectDiscount,
  newCampaignDiscount,
  setDiscount,
  discountFromCoupon,
  discountWording,
} from '../features/discount/discountSlice';
import OrderSummary, { getDiscountPlanCost } from './OrderSummary';
import {
  ValidateCoupon, CheckCoupon, Coupon, CouponType,
} from '../features/coupon/couponAPI';
import { postBookingsAsync } from '../features/finale/finaleSlice';
import {
  selectWantedBookingId,
  releaseWantedBooking,
} from '../features/bookings/bookingsSlice';
import {
  ArrivyTemplateExtraFields,
} from '../models/arrivy';
import { selectAddress } from '../features/address/addressSlice';
import { selectCampaign } from '../features/campaign/campaignSlice';
import { selectReservation, releaseReservation } from '../features/reservations/reservationsSlice';
import TimeOutErroModal from './TimeoutErrorModal';

export interface DetailProps {
  onSubmit: any
  previousStep: any
}

interface CouponForm {
  coupon: string
  plan: string
}

interface TermsForm {
  newDevelopment: boolean;
  oneoff: boolean
  monthly: boolean
  terms: boolean
  marketing: boolean
}

const illegalPromoCodes = ["SOMETHING12321"];

const CreditCardInfo = ({
  onSubmit, previousStep,
}: DetailProps) => {
  const cardRef = createRef();
  const dispatch = useAppDispatch();
  const signup = useAppSelector(selectSignUp);
  const booking = useAppSelector(selectWantedBookingId);
  const campaign = useAppSelector(selectCampaign);
  const discount = useAppSelector(selectDiscount);
  const reservation = useAppSelector(selectReservation);

  const [acceptedCoupon, setAcceptedCoupon] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [couponLoading, setCouponLoading] = useState<boolean>(false);
  const [
    proposedCoupon,
    setProposedCoupon,
  ] = useState<Coupon>({} as Coupon);

  const [showTimeOutErr, setShowTimeOutError] = useState(false);
  const [cardErr, setCardErr] = useState('');
  const [cardLoading, setCarLoading] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [chargeChecked, setChargeChecked] = useState(false);
  const [newDevelopmentChargeChecked, setNewDevelopmentChargeChecked] = useState(false);

  const ref = useRef<null | HTMLElement>(null);

  const { register, handleSubmit, formState } = useForm<CouponForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const termsForm = useForm<TermsForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const addr = useAppSelector(selectAddress);

  const applyDiscounts = (coupon: Coupon) => {
    dispatch(setCoupon(coupon));
    if (coupon.coupon_type === CouponType.ReferralCode || coupon.coupon_type === CouponType.SalesCommission) {
      dispatch(setDiscount(newCampaignDiscount("Referral Code Offer")));
    } else {
      dispatch(setDiscount(discountFromCoupon(coupon)));
    }
    if (coupon.coupon_id.includes("2MF") || coupon.coupon_id.includes("OMF")) {
      coupon.coupon_type = CouponType.MoveInCoupon;
      dispatch(setCoupon(coupon));
    }
  };

  const onCouponValidate = (data: CouponForm) => {
    setCouponLoading(true);
    setAcceptedCoupon(false);
    CheckCoupon(data.coupon, [signup.plan.id])
      .then((resp) => {
        const coupon = resp.data;

        if (
          Object.keys(discount).length !== 0
          && discountWording(discount)
          !== discountWording(discountFromCoupon(coupon))
        ) {
          setShowDiscountModal(true);
          setProposedCoupon(coupon);
          return;
        }

        applyDiscounts(coupon);
        setAcceptedCoupon(true);
      }).catch((err) => console.log(err))
      .finally(() => {
        setCouponLoading(false);
      });
  };

  const goBack = () => {
    dispatch(releaseWantedBooking());
    dispatch(releaseReservation());
    previousStep();
  };

  const validateCoupon = async (value: string) => {
    setAcceptedCoupon(false);
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
    });
    return (illegalPromoCodes.indexOf(value) === -1)
       && ValidateCoupon(
         value,
         addr,
         signup.plan.id,
       );
  };

  const tokenize = async (termsFormData: any) => {
    setMarketingTerms(termsFormData.marketing);
    setMarketingTerms(termsFormData.terms);
    setChargeChecked(termsFormData.monthly);
    setNewDevelopmentChargeChecked(termsFormData.newDevelopment);
    dispatch(setMarketingTerms({
      marketing: termsFormData.marketing ? 'YES' : 'NO',
      termsandcons: termsFormData.terms ? 'YES' : 'NO',
    }));

    setCarLoading(true);
    // Use card component ref to call tokenize method
    setCardErr('');
    // @ts-ignore
    await cardRef.current.tokenize({}).then((data: any) => {
      dispatch(setCbToken({ cb_token: data.vaultToken }));
      const newSignup = { ...signup, cb_token: data.vaultToken };
      newSignup.arrivyExtraFields = {
        ...newSignup.arrivyExtraFields,
        'I Agree to the Terms and Conditions': {
          type: 'TEXT',
          name: 'I Agree to the Terms and Conditions',
          value: termsFormData.terms ? 'YES' : 'NO',
        } as ArrivyTemplateExtraFields,
        'Marketing Emails': {
          type: 'TEXT',
          name: 'Marketing Emails',
          value: termsFormData.marketing ? 'YES' : 'NO',
        } as ArrivyTemplateExtraFields,
        'Building Code': {
          type: 'TEXT',
          name: 'Building Code',
          value: addr.uniqueCode,
        } as ArrivyTemplateExtraFields,
      };
      dispatch(postBookingsAsync({
        payload: { ...signup.arrivy, title: signup.arrivy.title },
        booking,
        signUp: newSignup,
        reservation,
      }));
      // Send ajax calls
      setCarLoading(false);
      onSubmit();
    }).catch((err: any) => {
      console.log('ERR', err);
      setCardErr('Credit card is invalid, please check details and try again.');
      setCarLoading(false);
    });
  };

  const getPlanCost = () => {
    const planCost = discount?.period !== 0 ? getDiscountPlanCost(Number(signup.plan.cost), discount) : Number(signup.plan.cost);

    let cost = signup.router.description2 === 'per month' ? planCost + Number(signup.router.cost) : planCost;
    cost = signup.addOns.includes("static-ip") ? cost + 9 : cost;

    return cost;
  };

  useEffect(() => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [cardLoading]);

  return (
    <Container>
      { showDiscountModal && (
      <DiscountModal
        currentDiscount={discount}
        proposedCoupon={proposedCoupon}
        onSubmit={(accepted) => {
          if (accepted) {
            applyDiscounts(proposedCoupon);
          }
          setShowDiscountModal(false);
        }}
      />
      ) }
      {showTimeOutErr && <TimeOutErroModal onSubmit={() => goBack()} />}
      <Row className="justify-content-md-center">
        <Col className="d-flex flex-column justify-content-center">
          <h1 className="fw-bold text-primary text-center mb-4">
            Secure Payment
          </h1>
          {reservation && reservation.expires_at && (
          <Alert
            variant="orange"
            className="mx-auto d-inline-block mb-4 my-2 px-2 py-1 text-white bg-orange text-center"
          >
            Hurry! Complete your order before
            {' '}
            <strong><Countdown callBack={setShowTimeOutError} endTime={dayjs(reservation.expires_at)} /></strong>
            {' '}
            to secure your preferred installation time of
            {' '}
            <strong>{pineAppleDay(new Date(booking.start)) }</strong>
            {' '}
            between
            {' '}
            <strong>{pineAppleTime(new Date(booking.start))}</strong>
            {' '}
            -
            {' '}
            <strong>{pineAppleTime(new Date(booking.end))}</strong>

            .
          </Alert>
          )}
        </Col>
      </Row>
      <Row className="position-relative">
        <Col md={8}>
          <CardComponent
            ref={cardRef}
            locale="en"
            className="fieldset field"
            styles={{
              base: {
                fontWeight: '400',
                fontFamily: '"General Sans", "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
                fontSize: '16px',
                color: '#6d6d6d',
                fontSmoothing: 'antialiased',
                ':focus': {
                  color: '#6d6d6d',
                },
                '::placeholder': {
                  color: '#fafafa',
                },
                ':focus::placeholder': {
                  color: '#6d6d6d',
                },
              },
            }}
            placeholder={{
              number: '',
              expiry: 'MM / YY',
              cvv: '',
            }}
            icon
          >
            <Row>
              <div
                style={{ borderTop: '2px solid' }}
                className="
              align-self-end
              py-2
              border-primary"
              >
                <h4>Payment Details</h4>
              </div>
            </Row>
            <Row className="mb-2">
              <Form.Group
                className="col-xs-4 col-md-12"
                as={Col}
                controlId="creditCardNumber"
              >
                <Form.Label>Card Number</Form.Label>
                <CardNumber
                  className="form-control input-with-radius"
                />
                <i className="ex1-bar" />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                className="col-xs-4 col-md-12"
                as={Col}
                controlId="cardHolderName"
              >
                <Form.Label>Cardholders Name</Form.Label>
                <Form.Control
                  className="form-control input-with-radius"
                  defaultValue={`${signup.arrivy.customer_first_name.toUpperCase()} ${signup.arrivy.customer_last_name.toUpperCase()}`}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                className="col-xs-2 col-md-6"
                as={Col}
                controlId="creditCardExpiry"
              >
                <Form.Label>Expiry (MM/YY)</Form.Label>
                <CardExpiry
                  className="form-control input-with-radius"
                />
                <i className="ex1-bar" />
              </Form.Group>
              <Form.Group
                className="col-xs-2 col-md-6"
                as={Col}
                controlId="creditCardCVV"
              >
                <Form.Label>CVV</Form.Label>
                <CardCVV
                  className="form-control input-with-radius"
                />
              </Form.Group>
            </Row>

            { (!campaign.referral && !campaign.moveIn) && (
              <Stack direction="horizontal" gap={2}>
                <Form.Group
                  className="col-xs-3 col-md-6 position-relative"
                  as={Col}
                  controlId="coupon"
                >
                  <Form.Label>Promo / Referral Code (if applicable)</Form.Label>
                  <Form.Control
                    className="input-with-radius"
                    defaultValue={signup.coupon?.coupon_id}
                    {...register('coupon', {
                      validate: validateCoupon,
                      onChange: () => { setShowButton(true); setAcceptedCoupon(false); },
                    })}
                  />
                  { formState.errors && formState?.errors?.coupon ? (
                    <Form.Text className="text-info position-absolute">
                      Coupon is invalid
                    </Form.Text>
                  )
                    : signup?.coupon && acceptedCoupon && (
                    <Form.Text className="text-primary position-absolute">
                      { signup?.coupon.coupon_type === 2 ? 'Referral' : 'Coupon' }
                      {' '}
                      code is accepted
                    </Form.Text>
                    )}
                </Form.Group>
                {showButton && (
                <Button
                  className={signup.coupon && !formState.errors.coupon ? "mt-3 position-relative items-start" : "mt-3 position-relative"}
                  variant={formState.errors && formState.errors.coupon ? "danger" : "primary"}
                  size="lg"
                  onClick={handleSubmit(onCouponValidate)}
                  style={
                    /* eslint-disable */
                    acceptedCoupon ?
                      {background: "transparent", borderWidth: "0px"}
                      : formState.errors && formState.errors.coupon && {color: "white"}
                  }
                >
                  {couponLoading
                    && (
                      <Spinner
                        style={{top: "0.65em", left: "1em"}}
                        className="position-absolute"
                        animation="grow"
                      />
                    )}
                  {' '}

                  {
                    /* eslint-disable */
                    acceptedCoupon ? <CheckCircleFill color='green' size="40px" style={{
                      position: "absolute",
                      left: "10px", top: "-3px"
                    }} />
                      : formState.errors && formState.errors.coupon ? "Try again" : "Validate"}
                </Button>
)}
              </Stack>
            )}
            <Row className="mt-4">
              <h4>Your acceptance</h4>
            </Row>
            <Stack gap={2} direction="vertical" className="mb-3">
              <Form.Check
                type="radio"
                id="monthly"
                checked={chargeChecked}
                onClick={() => setChargeChecked(!chargeChecked)}
                label={(
                  <span>
                    {' I agree to a monthly charge of '}
                    <b>
                      $
                      {getPlanCost()}
                    </b>
                    {signup.addOns.includes('static-ip') && ' (inclusive of monthly Static IP charge)'}
                    {' and for this amount to be automatically charged to the payment details provided.'}
                    { discount.terms !== undefined
                      ? ` ${discount.terms}`
                      : null }
                  </span>
)}
                {...termsForm.register('monthly', {
                  required: `Please agree to a monthly charge of $${getPlanCost()}`,
                })}
              />
              { addr.buildingCategory === 'greenfield'
              && (
              <Form.Check
                type="radio"
                id="newDevelopment"
                checked={newDevelopmentChargeChecked}
                onClick={() => setNewDevelopmentChargeChecked(!newDevelopmentChargeChecked)}
                label={(
                  <span>
                    I agree to a one-off New Development Charge of
                    {' '}
                    <strong>$300</strong>
                  </span>
                  )}
                {...termsForm.register('newDevelopment', {
                  required: "Please agree to the New Development Charge of $300",
                })}
              />
              )}
              <Form.Check
                type="radio"
                id="terms"
                onClick={() => setTermsChecked(!termsChecked)}
                checked={termsChecked}
                label={(
                  <span>
                    {' I confirm that I have read and agree to '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.pineapple.net.au/wp-content/uploads/PN_General-Terms.pdf"
                    >
                      terms and conditions
                    </a>
                    {' of my plan.'}
                  </span>
)}
                {...termsForm.register('terms', {
                  required: 'Please confirm that you have read and agree to the terms and conditions of your plan.',
                })}
              />
              <Form.Check
                type="radio"
                id="marketing"
                checked={marketingChecked}
                onClick={() => setMarketingChecked(!marketingChecked)}
                label={(
                  <div style={{ width: '70%' }}>
                    {'I would like to receive marketing and promotional communications from Pineapple Net in accordance with the '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.pineapple.net.au/wp-content/uploads/PN_Privacy-Policy_12.23.pdf">
                      Privacy Policy.
                    </a>
                    {' '}
                    Pineapple Net is committed to protecting and respecting your
                    privacy. We will only use your personal information to
                    provide information you have requested, adminster your account,
                    and deliver our products and services to you.
                  </div>
              )}
                {...termsForm.register('marketing', {
                })}
              />
            </Stack>
            <h4 className="text-center text-info mt-3">{cardErr}</h4>
          </CardComponent>
        </Col>
        <Col>
          <OrderSummary />
        </Col>
      </Row>
      <Row><Col className="text-info">{termsForm?.formState?.errors?.newDevelopment ? termsForm.formState.errors.newDevelopment.message : '' }</Col></Row>
      <Row><Col className="text-info">{termsForm?.formState?.errors?.monthly ? termsForm.formState.errors.monthly.message : '' }</Col></Row>
      <Row>
        <Col className="text-info">{termsForm?.formState?.errors?.terms ? termsForm.formState.errors.terms.message : '' }</Col>

      </Row>
      <Stack
        gap={2}
        className="justify-content-center my-4"
        direction="horizontal"
      >
        <Button
          id="payment_go_back"
          onClick={goBack}
          size="lg"
          variant="outline-light"
        >
          <span className="text-body px-2">Go back</span>
        </Button>
        <Button
          id="payment_submit"
          onClick={termsForm.handleSubmit(tokenize)}
          size="lg"
          variant="primary"
          disabled={cardLoading}
        >
          <span ref={ref} className="px-2">{!cardLoading ? 'Submit' : 'Please wait...'}</span>
        </Button>
      </Stack>
    </Container>
  );
};

export default CreditCardInfo;
